import axios from 'axios'

let APIURL = process.env.REACT_APP_SERVER_URL //NEXT_PUBLIC_PRODUCTION_SERVER_URL

//manage users section
export const getAllUser = async (query) => {
  try {
    let accessToken = localStorage.getItem('accessToken')
    const apiUrl = `${APIURL}/adminPanel/getAllUser?${query}`

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        Authorization: `Bearer ${accessToken}`,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const getUser = async (userID) => {
  try {
    let accessToken = localStorage.getItem('accessToken')

    const apiUrl = `${APIURL}/adminPanel/getUserProfile/${userID}`

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        Authorization: `Bearer ${accessToken}`,
      },
    })

    return response.data.data
  } catch (error) {
    return error.message
  }
}

export const deleteUserAccount = async (userIDs) => {
  try {
    let accessToken = localStorage.getItem('accessToken')

    const apiUrl = `${APIURL}/user/deleteAccount/${userIDs[0]}`

    let response = await axios.delete(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        Authorization: `Bearer ${accessToken}`,
      },
    })

    return response.data
  } catch (error) {
    return error.message
  }
}

export const activeInactiveUser = async (userIDs, status) => {
  try {
    let accessToken = localStorage.getItem('accessToken')
    // /api/v1/anonymous/users/21/updatestatus?status=true

    let formdata = new FormData()
    formdata = {
      userIds: userIDs,
      status: status,
    }

    const apiUrl = `${APIURL}/api/v1/anonymous/users/updatestatus`

    let response = await axios.put(apiUrl, formdata, {
      headers: {
        'Content-Type': `application/json`,
        'x-access-token': accessToken,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const registerNewUser = async (email, password, name) => {
  let formdata
  formdata = {
    email: email,
    password: password,
    name: name,
  }

  const apiUrl = `${APIURL}/adminPanel/createAdminUser`

  let response = await axios.post(apiUrl, formdata, {
    headers: {
      'Content-Type': `application/json`,
    },
  })

  return response.data
}

export const loginWithEmailandPassword = async (email, password) => {
  let formdata = new FormData()
  formdata = {
    email: email,
    password: password,
  }

  const apiUrl = `${APIURL}/adminPanel/login/admin`

  let response = await axios.post(apiUrl, formdata, {
    headers: {
      'Content-Type': `application/json`,
    },
  })

  if (response.data.status == 200) {
    localStorage.setItem('accessToken', response.data.data.token)
    sessionStorage.setItem('accessToken', response.data.data.token)
    localStorage.setItem('loginUserId', response.data.data.admin_user_id)
  }

  return response.data
}

export const getAllEntries = async (userId, page, pageSize, filter, type) => {
  try {
    let accessToken = localStorage.getItem('accessToken')
    let apiUrl = `${APIURL}/entry/getEntriesByMonthYear?user_id=${userId}&page=${page}&pageSize=${pageSize}&year=${filter.year}&month=${filter.month}&type=${type}`

    if (filter.categoryId.length !== 0) {
      apiUrl += `&category_id=${filter.categoryId}`
    }

    if (filter.walletId.length !== 0) {
      apiUrl += `&wallet_id=${filter.walletId}`
    }

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        Authorization: `Bearer ${accessToken}`,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

//entry

export const addEntry = async (data, type, userId) => {
  try {
    let formData = new FormData()
    formData = {
      name: data.title,
      type: type,
      description: data.description,
      amount: data.amount,
      closing_balance: 0,
      date: data.entry_date,
      category_id: parseInt(data.category_id_or_to_wallet_id),
      wallet_id: parseInt(data.wallet_id),
    }

    let accessToken = localStorage.getItem('accessToken')
    const apiUrl = `${APIURL}/entry/addEntry/${userId}`

    let response = await axios.post(apiUrl, formData, {
      headers: {
        'Content-Type': `multipart/form-data;`,
        Authorization: `Bearer ${accessToken}`,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const updateEntry = async (data, type, userId) => {
  try {
    let formData = new FormData()
    formData = {
      entry_id: data.entry_id,
      name: data.title,
      type: type,
      description: data.description,
      amount: data.amount,
      closing_balance: 0,
      date: data.entry_date,
      category_id: data.category_id_or_to_wallet_id,
      wallet_id: data.wallet_id,
    }

    let accessToken = localStorage.getItem('accessToken')
    const apiUrl = `${APIURL}/entry/updateEntry/${userId}`

    let response = await axios.post(apiUrl, formData, {
      headers: {
        'Content-Type': `multipart/form-data;`,
        Authorization: `Bearer ${accessToken}`,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const deleteEntry = async (entryId, userId) => {
  try {
    let formData = new FormData()
    formData = {
      entry_id: entryId,
    }

    let accessToken = localStorage.getItem('accessToken')
    const apiUrl = `${APIURL}/entry/deleteEntry/${userId}`

    let response = await axios.post(apiUrl, formData, {
      headers: {
        'Content-Type': `application/json`,
        Authorization: `Bearer ${accessToken}`,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

// transfer

export const addTransfer = async (data, userId) => {
  try {
    let formData = new FormData()
    formData = {
      from_wallet_id: parseInt(data.wallet_id),
      to_wallet_id: parseInt(data.category_id_or_to_wallet_id),
      name: data.title,
      description: data.description,
      amount: data.amount,
      closing_balance: 0,
      tran_date: data.entry_date,
    }

    let accessToken = localStorage.getItem('accessToken')
    const apiUrl = `${APIURL}/transfer/addTransferEntry/${userId}`

    let response = await axios.post(apiUrl, formData, {
      headers: {
        'Content-Type': `multipart/form-data;`,
        Authorization: `Bearer ${accessToken}`,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const updateTransfer = async (data, userId) => {
  try {
    let formData = new FormData()
    formData = {
      transfer_id: data.transfer_id,
      from_wallet_id: parseInt(data.wallet_id),
      to_wallet_id: parseInt(data.category_id_or_to_wallet_id),
      name: data.title,
      description: data.description,
      amount: data.amount,
      closing_balance: 0,
      tran_date: data.entry_date,
    }

    let accessToken = localStorage.getItem('accessToken')
    const apiUrl = `${APIURL}/transfer/updateTransferEntry/${userId}`

    let response = await axios.post(apiUrl, formData, {
      headers: {
        'Content-Type': `multipart/form-data;`,
        Authorization: `Bearer ${accessToken}`,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const deleteTransfer = async (transferId, userId) => {
  try {
    let formData = new FormData()
    formData = {
      transfer_id: transferId,
    }

    let accessToken = localStorage.getItem('accessToken')
    const apiUrl = `${APIURL}/transfer/deleteTransferEntry/${userId}`

    let response = await axios.post(apiUrl, formData, {
      headers: {
        'Content-Type': `application/json`,
        Authorization: `Bearer ${accessToken}`,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

// category

export const getAllCategoryList = async (userId, type) => {
  try {
    let accessToken = localStorage.getItem('accessToken')
    const apiUrl = `${APIURL}/category/getCategoryList?user_id=${userId}&type=${type}`

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        Authorization: `Bearer ${accessToken}`,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const addCategory = async (data, userId) => {
  try {
    let formData = new FormData()
    formData = {
      name: data.name,
      type: data.type,
    }

    let accessToken = localStorage.getItem('accessToken')
    const apiUrl = `${APIURL}/category/createCategory/${userId}`

    let response = await axios.post(apiUrl, formData, {
      headers: {
        'Content-Type': `application/json`,
        Authorization: `Bearer ${accessToken}`,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const updateCategory = async (data, userId) => {
  try {
    let formData = {
      name: data.name,
      category_id: data.category_id,
      disabled: data.disabled,
    }

    let accessToken = localStorage.getItem('accessToken')
    const apiUrl = `${APIURL}/category/updateCategoryName/${userId}`

    let response = await axios.post(apiUrl, formData, {
      headers: {
        'Content-Type': `application/json`,
        Authorization: `Bearer ${accessToken}`,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

// wallet

export const getAllWalletList = async (userId, disabled) => {
  try {
    let accessToken = localStorage.getItem('accessToken')
    const apiUrl = `${APIURL}/wallet/getWalletList?user_id=${userId}&disabled=${disabled}&type=web`

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        Authorization: `Bearer ${accessToken}`,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const addWallet = async (data, userId) => {
  try {
    let formData = new FormData()
    formData = {
      name: data.name,
      amount: data.amount,
      is_credit_card: data.is_credit_card,
    }

    let accessToken = localStorage.getItem('accessToken')
    const apiUrl = `${APIURL}/wallet/createWallet/${userId}`

    let response = await axios.post(apiUrl, formData, {
      headers: {
        'Content-Type': `application/json`,
        Authorization: `Bearer ${accessToken}`,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const updateWallet = async (data, userId) => {
  try {
    let formData = {
      name: data.name,
      amount: data.amount,
      is_credit_card: data.is_credit_card,
      wallet_id: data.wallet_id,
      disabled: data.disabled,
    }

    let accessToken = localStorage.getItem('accessToken')
    const apiUrl = `${APIURL}/wallet/updateWalletName/${userId}`

    let response = await axios.put(apiUrl, formData, {
      headers: {
        'Content-Type': `application/json`,
        Authorization: `Bearer ${accessToken}`,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

// policy

export const getAllPolicy = async () => {
  try {
    let accessToken = localStorage.getItem('accessToken')
    const apiUrl = `${APIURL}/policies/getAllPolicy`

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        Authorization: `Bearer ${accessToken}`,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const UpdatePolicy = async (policyId, html, title) => {
  try {
    let data = {
      policyId: policyId,
      html: html,
      title: title,
    }

    let accessToken = localStorage.getItem('accessToken')
    const apiUrl = `${APIURL}/policies/updatePolicy`

    let response = await axios.post(apiUrl, data, {
      headers: {
        'Content-Type': `application/json`,
        Authorization: `Bearer ${accessToken}`,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

// ads setting

export const getAllAds = async () => {
  try {
    let accessToken = localStorage.getItem('accessToken')
    const apiUrl = `${APIURL}/ads/getAllAdsforAdmin`

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        Authorization: `Bearer ${accessToken}`,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const UpdateAds = async (adsId, adskey) => {
  try {
    let data = {
      ads: {
        ads_key: adskey,
      },
    }

    let accessToken = localStorage.getItem('accessToken')
    const apiUrl = `${APIURL}/ads/updateAds/${adsId}`

    let response = await axios.post(apiUrl, data, {
      headers: {
        'Content-Type': `application/json`,
        Authorization: `Bearer ${accessToken}`,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

//config

export const getConfigurationById = async (configId) => {
  try {
    let accessToken = localStorage.getItem('accessToken')
    const apiUrl = `${APIURL}/config/${configId}`

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        Authorization: `Bearer ${accessToken}`,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const updateConfigurationById = async (configId, values) => {
  try {
    let data = {
      values: values,
    }

    let accessToken = localStorage.getItem('accessToken')
    const apiUrl = `${APIURL}/config/${configId}`

    let response = await axios.put(apiUrl, data, {
      headers: {
        'Content-Type': `application/json`,
        Authorization: `Bearer ${accessToken}`,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

// default category

export const getAllDefaultCategoryList = async () => {
  try {
    let accessToken = localStorage.getItem('accessToken')
    const apiUrl = `${APIURL}/adminPanel/getAllDefaultCategory`

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        Authorization: `Bearer ${accessToken}`,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const createDefaultCategory = async (name, type, cateogry_icon) => {
  try {
    let data = new FormData()

    //let fileName = name + '_' + name  + "." + cateogry_icon.name.split(".").pop()
    data.append('icon', cateogry_icon, cateogry_icon.name)
    data.append(`type`, type)
    data.append(`name`, name)

    let accessToken = localStorage.getItem('accessToken')
    const apiUrl = `${APIURL}/adminPanel/createCategory`

    let response = await axios.post(apiUrl, data, {
      headers: {
        'Content-Type': `multipart/form-data;`,
        Authorization: `Bearer ${accessToken}`,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const updateDefaultCategory = async (cat_id, name, type, cateogry_icon, oldCategoryPath) => {
  try {
    let data = new FormData()

    data.append('icon', cateogry_icon, oldCategoryPath)
    data.append(`type`, type)
    data.append(`name`, name)

    let accessToken = localStorage.getItem('accessToken')
    const apiUrl = `${APIURL}/adminPanel/updateCategory/${cat_id}`

    let response = await axios.post(apiUrl, data, {
      headers: {
        'Content-Type': `multipart/form-data;`,
        Authorization: `Bearer ${accessToken}`,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

// cron scheduling

export const scheduleCron = async (type, time, title, message) => {
  try {
    let data = {
      type: type,
      time: time,
      title: title,
      message: message,
    }

    let accessToken = localStorage.getItem('accessToken')
    const apiUrl = `${APIURL}/adminPanel/scheduleCron`

    let response = await axios.post(apiUrl, data, {
      headers: {
        'Content-Type': `application/json`,
        Authorization: `Bearer ${accessToken}`,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const sendNotificationToSelectedUser = async (userIds, title, message) => {
  try {
    let data = {
      type: 'now',
      userIds: userIds,
      title: title,
      message: message,
    }

    console.log(data)

    let accessToken = localStorage.getItem('accessToken')
    const apiUrl = `${APIURL}/adminPanel/sendNotificationToSelectedUser`

    let response = await axios.post(apiUrl, data, {
      headers: {
        'Content-Type': `application/json`,
        Authorization: `Bearer ${accessToken}`,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}
