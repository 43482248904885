import React, {useEffect, useState} from 'react'
import ToastUtils, {ErrorToastUtils} from '../../../../../../utils/ToastUtils'
import {
  UpdateAds,
  UpdatePolicy,
  getAllAds,
  getConfigurationById,
  updateConfigurationById,
} from '../../../../../../API/api-endpoint'

const AdsControlPlugin = () => {
  const [adsConfig, setAdsConfig] = useState<any>([])
  const [tempConfig, setTempConfig] = useState<any>({})
  const [adsConfiguration, setAdsConfiguration] = useState<any>({isEnabled: true})

  useEffect(() => {
    getConfiguration()
  }, [])

  const handleChange = (event: any, index: any) => {
    let value = event.target.value
    let adsId = adsConfig[index].ads_id
    let oldData: any = [...adsConfig]
    oldData[index].ads_key = value
    setAdsConfig(oldData)
    setTempConfig({adsId: adsId, adsKey: value})
  }

  const onBlurConfigUpdate = () => {
    updateConfiguration(tempConfig.adsId, tempConfig.adsKey)
  }

  const getConfiguration = async () => {
    let result = await getAllAds()
    let result1 = await getConfigurationById(2)
    if (result.status === 200) {
      console.log(result.data)
      setAdsConfig(result.data)
    }
    if (result1.status === 200) {
      let parsedData = JSON.parse(result1.data.values)
      setAdsConfiguration({isEnabled: parsedData.isEnabled})
    }
  }

  const updateConfiguration = async (adsId: any, adsKey: any) => {
    let result = await UpdateAds(adsId, adsKey)
    if (result.status === 200) {
      getConfiguration()
      ToastUtils({type: 'success', message: 'Configuration Saved SuccessFully'})
    } else {
      ErrorToastUtils()
    }
  }

  const updateAdsConfiguration = async (event: any) => {
    let value = event.target.checked
    let result = await updateConfigurationById(2, {isEnabled: value})
    if (result.status === 200) {
      getConfiguration()
      ToastUtils({type: 'success', message: 'Configuration Saved SuccessFully'})
    } else {
      ErrorToastUtils()
    }
  }

  // updateConfigurationById

  return (
    <div className='card'>
      <div className='card-title p-8'>
        <h2>Ads settings</h2>
      </div>
      <div className='card-body'>
        <div className='row no-gutters'>
          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Enable Ads</strong>
            </p>
            <p className='text-muted'>Enable or disable Ads</p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='form-check form-switch'>
              <input
                className='form-check-input'
                type='checkbox'
                role='switch'
                id='flexSwitchCheckDefault'
                name='isUndoProfile'
                checked={adsConfiguration.isEnabled}
                onChange={(event) => updateAdsConfiguration(event)}
              />
            </div>
          </div>
          {adsConfig.map((ads: any, index: any) => {
            return (
              <>
                <div className='col-lg-4 card-body bg-light'>
                  <p>
                    <strong className='headings-color'>{ads.ads_type}</strong>
                  </p>
                  <p className='text-muted'>-</p>
                </div>
                <div className='col-lg-8 card-form__body card-body  bg-white'>
                  <div className='flex'>
                    <input
                      type='text'
                      className='form-control'
                      value={ads.ads_key}
                      onChange={(event) => handleChange(event, index)}
                      onBlur={onBlurConfigUpdate}
                    />
                  </div>
                </div>
              </>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default AdsControlPlugin
