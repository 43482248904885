import React, {useEffect, useState} from 'react'
import {
  UpdatePolicy,
  getAllPolicy,
  getConfigurationById,
  scheduleCron,
  updateConfigurationById,
} from '../../../../../../API/api-endpoint'
import ToastUtils, {ErrorToastUtils} from '../../../../../../utils/ToastUtils'

const NotificationPlugin = () => {
  const [notificationConfiguration, setNotificationConfiguration] = useState<any>([])
  const [selectedNotification, setSelectedNotification] = useState<any>({
    title: '',
    message: '',
    type: '',
    time: '',
  })
  const [isEdit, setIsEdit] = useState(false)
  const [indexOfEditEntry, setIndexOfEditEntry] = useState(0)

  useEffect(() => {
    getConfiguration()
  }, [])

  const getConfiguration = async () => {
    let result = await getConfigurationById(3)
    if (result.status === 200) {
      let parsedData = JSON.parse(result.data.values)
      setNotificationConfiguration(parsedData)
    }
  }

  const editConfiguration = async (index: any) => {
    setIndexOfEditEntry(index)
    let type = notificationConfiguration[index].type
    let time = notificationConfiguration[index].time
    let title = notificationConfiguration[index].title
    let message = notificationConfiguration[index].message

    setSelectedNotification({title: title, message: message, time: time, type: type})
    setIsEdit(true)
  }

  const deleteConfiguration = async (index: any) => {
    let oldConfig = [...notificationConfiguration]
    oldConfig.splice(index, 1)

    let result = await updateConfigurationById(3, oldConfig)
    if (result.status === 200) {
      getConfiguration()
      ToastUtils({type: 'success', message: 'Configuration Deleted SuccessFully'})
    } else {
      ErrorToastUtils()
    }
  }

  const updateConfiguration = async (config: any) => {
    if (
      selectedNotification.title.length !== 0 ||
      selectedNotification.message.length !== 0 ||
      selectedNotification.type.length !== 0
    ) {
      let oldConfig = [...config]
      if (isEdit) {
        oldConfig.splice(indexOfEditEntry, 1)
      }
      oldConfig.push(selectedNotification)

      let result = await updateConfigurationById(3, oldConfig)
      if (result.status === 200) {
        setSelectedNotification({
          title: '',
          message: '',
          type: '',
          time: '',
        })
        getConfiguration()
        ToastUtils({type: 'success', message: 'Configuration Saved SuccessFully'})
      } else {
        ErrorToastUtils()
      }
    } else {
      ToastUtils({type: 'error', message: 'Please Fill All Fields'})
    }
  }

  const scheduleJob = async (index: any) => {
    let type = notificationConfiguration[index].type
    let time = notificationConfiguration[index].time
    let title = notificationConfiguration[index].title
    let message = notificationConfiguration[index].message

    let result = await scheduleCron(type, time, title, message)
    if (result.status == 200) {
      ToastUtils({type: 'success', message: 'Cron Schedule Successfully'})
    } else {
      ToastUtils({type: 'error', message: 'error in Cron Scheduling'})
    }
  }

  return (
    <div className='card'>
      <div className='card-body'>
        <div className='row no-gutters'>
          <div className='row'>
            <div className='col-lg-4 card-body bg-light'>
              <p>
                <strong className='headings-color'>Title</strong>
              </p>
            </div>
            <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
              <div className='flex'>
                <input
                  className='form-control'
                  name='policy'
                  value={selectedNotification.title}
                  onChange={(event) =>
                    setSelectedNotification({
                      ...selectedNotification,
                      title: event.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div className='col-lg-4 card-body bg-light'>
              <p>
                <strong className='headings-color'>Message</strong>
              </p>
            </div>
            <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
              <div className='flex'>
                <textarea
                  className='form-control'
                  name='policy'
                  value={selectedNotification.message}
                  onChange={(event) =>
                    setSelectedNotification({
                      ...selectedNotification,
                      message: event.target.value,
                    })
                  }
                  rows={10}
                  cols={60}
                />
              </div>
            </div>

            <div className='col-lg-4 card-body bg-light'>
              <p>
                <strong className='headings-color'>Type</strong>
              </p>
            </div>
            <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
              <div
                onChange={(event: any) =>
                  setSelectedNotification({...selectedNotification, type: event.target.value})
                }
              >
                <input
                  type='radio'
                  value='now'
                  name='now'
                  checked={selectedNotification.type === 'now'}
                />
                Now &nbsp;
                <input
                  type='radio'
                  value='today'
                  name='today'
                  checked={selectedNotification.type === 'today'}
                />
                &nbsp; Today &nbsp;
                <input
                  type='radio'
                  value='everyday'
                  name='everyday'
                  checked={selectedNotification.type === 'everyday'}
                />
                &nbsp; EveryDay &nbsp;
                <input
                  type='radio'
                  value='custom'
                  name='custom'
                  checked={selectedNotification.type === 'custom'}
                />
                &nbsp; Custom &nbsp;
              </div>
              {selectedNotification.type !== 'now' && (
                <input
                  type={selectedNotification.type === 'custom' ? 'datetime-local' : 'time'}
                  id='customDate'
                  name='customDate'
                  value={selectedNotification.time}
                  onChange={(event) =>
                    setSelectedNotification({...selectedNotification, time: event.target.value})
                  }
                />
              )}
            </div>

            <div className='d-flex justify-content-end mt-4'>
              <button
                className='btn btn-primary'
                onClick={() => {
                  updateConfiguration(notificationConfiguration)
                }}
              >
                {isEdit ? 'Edit' : 'Save'}
              </button>
            </div>
          </div>
        </div>

        <div className='container'>
          <h2>Scheduled Notifications</h2>
          <p>-</p>
          <table className='table table-bordered'>
            <thead>
              <tr>
                <th>Title</th>
                <th>Message</th>
                <th>Type</th>
                <th>Time</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {notificationConfiguration.map((notification: any, index: any) => {
                return (
                  <tr key={index}>
                    <td>{notification.title}</td>
                    <td>{notification.message}</td>
                    <td>{notification.type}</td>
                    <td>{notification.time}</td>
                    <td>
                      <button className='btn btn-primary btn-sm' onClick={() => scheduleJob(index)}>
                        Schedule
                      </button>
                      <button
                        className='ms-3 btn btn-secondary btn-sm'
                        onClick={() => editConfiguration(index)}
                      >
                        Edit
                      </button>
                      <button
                        className='ms-3 btn btn-danger btn-sm'
                        onClick={() => deleteConfiguration(index)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default NotificationPlugin
