import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import Plugins from '../modules/apps/plugins/Plugins'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/apps/profile/ProfilePage'))
  //const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='admin/auth/*' element={<Navigate to='/admin/dashboard' />} />
        {/* Pages */}
        <Route path='admin/dashboard' element={<DashboardWrapper />} />
        <Route path='admin/builder' element={<BuilderPageWrapper />} />
        {/* Lazy Modules */}

        <Route
          path='admin/apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* we have created 2 routes for profile page bcs we need a differentiate between normal user and anonymous user */}
        <Route
          path='admin/apps/users-profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='admin/plugins/*'
          element={
            <SuspensedView>
              <Plugins />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/admin/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
