import React, {useEffect, useRef, useState} from 'react'
import {
  getAllDefaultCategoryList,
  createDefaultCategory,
  updateDefaultCategory,
} from '../../../../../../API/api-endpoint'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import clsx from 'clsx'
import ToastUtils, {ErrorToastUtils} from '../../../../../../utils/ToastUtils'

const DefaultCategoryPlugin = () => {
  const hiddenFileInput = useRef<HTMLInputElement>(document.createElement('input'))

  const [defaultCategoryList, setDefaultCategoryList] = useState<any>([])
  const [currentImageTempPath, setCurrentImageTempPath] = useState<any>('')
  const [isImageUploaded, setisImageUploaded] = useState<any>(false)
  const [file, setFile] = useState('')
  const [categoryName, setCategoryName] = useState('')
  const [categoryType, setCategoryType] = useState('')
  const [selectedDefaultCategoryID, setSelectedDefaultCategoryID] = useState(0)
  const [selectedDefaultCategoryName, setSelectedDefaultCategoryName] = useState('')
  const [getDefaultOnCategoryChange, setGetDefaultOnCategoryChange] = useState(1)
  const [IsEditDefaultCategory, setIsDefaultCategory] = useState(false)
  const [selectedCategoryIcon, setSelectedCategoryIcon] = useState('')

  useEffect(() => {
    getAllCategoryList()
  }, [getDefaultOnCategoryChange])

  const getAllCategoryList = async () => {
    let result = await getAllDefaultCategoryList()
    if (result.status === 200) {
      //const groupedGifts: any = groupGiftsByCategoryId(result.data)
      setDefaultCategoryList(result.data)
    }
  }

  const handleClick = () => {
    const fileInput = document.getElementById(`fileInput`)
    fileInput?.click()
  }

  const handleIconChange = (event: any) => {
    setisImageUploaded(true)
    const fileUploaded = event.target.files[0]
    var tmppath = URL.createObjectURL(event.target.files[0])
    setCurrentImageTempPath(tmppath)
    setFile(fileUploaded)
  }

  const createCategory = async () => {
    if (categoryName.trim().length === 0 || categoryType.trim().length === 0) {
      ToastUtils({type: 'error', message: 'Please Fill All Data'})
    } else {
      let result = await createDefaultCategory(categoryName, categoryType, file)
      if (result.status === 200) {
        getAllCategoryList()
        ToastUtils({type: 'success', message: 'Category Is Created'})
        setCategoryType('')
        setCategoryName('')
        setFile('')
        setisImageUploaded(false)
      } else {
        ErrorToastUtils()
      }
    }
  }

  const updateCategory = async () => {
    if (categoryName.trim().length === 0 || categoryType.trim().length === 0) {
      ToastUtils({type: 'error', message: 'Please Fill All Data'})
    } else {
      let result = await updateDefaultCategory(
        selectedDefaultCategoryID,
        selectedDefaultCategoryName,
        categoryType,
        file,
        selectedCategoryIcon
      )
      if (result.status === 200) {
        ToastUtils({type: 'success', message: 'Category Is Updated'})
        setCategoryType('')
        setCategoryName('')
        setFile('')
        setCurrentImageTempPath('')
        setisImageUploaded(false)
        getAllCategoryList()
      } else {
        ErrorToastUtils()
      }
    }
  }

  return (
    <>
      <div
        className='mb-4 d-flex justify-content-end'
        data-bs-toggle='modal'
        data-bs-target='#category_model'
        onClick={() => {
          setSelectedDefaultCategoryID(0)
          setSelectedDefaultCategoryName('')
        }}
      >
        <button className='btn btn-primary'>Add Category</button>
      </div>

      <div className='row'>
        <div className='col-6'>
          <div className='card mb-5'>
            <h5 className=' p-4'>Expense</h5>
            <div className='card-body row d-flex p-4 '>
              {defaultCategoryList
                .filter((category: any) => category.type === 'E')
                .map((category: any, index: any) => {
                  return (
                    <div
                      key={index}
                      className='col-3'
                      data-bs-toggle='modal'
                      data-bs-target='#category_model'
                      onClick={() => {
                        setIsDefaultCategory(true)
                        setSelectedDefaultCategoryID(category?.category_id)
                        setSelectedDefaultCategoryName(category?.name)

                        setCategoryName(category?.name)
                        setCategoryType(category?.type)
                        setCurrentImageTempPath(
                          `${process.env.REACT_APP_SERVER_URL}/${category?.category_icon}`
                        )
                        setSelectedCategoryIcon(category?.category_icon)
                        setisImageUploaded(true)
                      }}
                    >
                      <img
                        src={`${process.env.REACT_APP_SERVER_URL}/${category.category_icon}`}
                        height={50}
                        width={50}
                        alt='icon'
                        loading='lazy'
                      />

                      <div className='mb-4'>
                        <h6 className='fs-7 mt-2'>{category.name}</h6>
                      </div>
                    </div>
                  )
                })}
            </div>
          </div>
        </div>
        <div className='col-6'>
          <div className='card mb-5'>
            <h5 className=' p-4'>Income</h5>
            <div className='card-body row d-flex p-4 '>
              {defaultCategoryList
                .filter((category: any) => category.type === 'I')
                .map((category: any, index: any) => {
                  return (
                    <div
                      key={index}
                      className='col-3'
                      data-bs-toggle='modal'
                      data-bs-target='#category_model'
                      onClick={() => {
                        setIsDefaultCategory(true)
                        setSelectedDefaultCategoryID(category?.category_id)
                        setSelectedDefaultCategoryName(category?.name)

                        setCategoryName(category?.name)
                        setCategoryType(category?.type)
                        setCurrentImageTempPath(
                          `${process.env.REACT_APP_SERVER_URL}/${category?.category_icon}`
                        )
                        setSelectedCategoryIcon(category?.category_icon)
                        setisImageUploaded(true)
                      }}
                    >
                      <img
                        src={`${process.env.REACT_APP_SERVER_URL}/${category.category_icon}`}
                        height={50}
                        width={50}
                        alt='icon'
                        loading='lazy'
                      />

                      <div className='mb-4'>
                        <h6 className='fs-7 mt-2'>{category.name}</h6>
                      </div>
                    </div>
                  )
                })}
            </div>
          </div>
        </div>
      </div>

      <div className='modal fade' tabIndex={-1} id='category_model'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>
                {IsEditDefaultCategory ? 'Update Category' : 'Add Category'}
              </h5>
            </div>
            <div className='modal-body'>
              <div className='d-flex align-items-center '>
                <div className='symbol symbol-50px overflow-visible me-3'>
                  <div
                    onClick={() => {
                      handleClick()
                      setisImageUploaded(false)
                    }}
                  >
                    <img
                      src={
                        !isImageUploaded
                          ? toAbsoluteUrl('/media/plugins/jstree/add.png')
                          : currentImageTempPath
                      }
                      height={82}
                      width={82}
                      alt='icon'
                      loading='lazy'
                    />
                  </div>

                  <input
                    type='file'
                    name='icon'
                    id={`fileInput`}
                    onChange={(e) => handleIconChange(e)}
                    ref={hiddenFileInput}
                    style={{display: 'none'}} // Make the file input element invisible
                    accept='image/svg+xml'
                  />
                </div>

                <div className='flex-grow-1'>
                  <input
                    placeholder='Enter Category Name'
                    type='text'
                    name='name'
                    className={clsx('form-control form-control-solid mb-3 mb-lg-0 me-3')}
                    autoComplete='off'
                    value={categoryName}
                    onChange={(e) => setCategoryName(e.target.value)}
                  />

                  <select
                    className='form-select form-select-solid fw-bolder mb-3 mb-lg-0 mt-4'
                    data-kt-select2='true'
                    data-placeholder='Select option'
                    data-allow-clear='true'
                    data-hide-search='true'
                    name='categoryType'
                    value={categoryType}
                    onChange={(e) => setCategoryType(e.target.value)}
                  >
                    <option value=''>Select Type</option>
                    <option value='I'>Income</option>
                    <option value='E'>Expense</option>
                  </select>
                </div>
              </div>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-light'
                data-bs-dismiss='modal'
                onClick={() => {
                  setIsDefaultCategory(false)
                  setSelectedDefaultCategoryID(0)
                  setCategoryName('')
                  setCategoryType('')
                  setCurrentImageTempPath('')
                  setisImageUploaded(false)
                }}
              >
                Close
              </button>
              <button
                type='button'
                className='btn btn-primary'
                data-bs-dismiss='modal'
                onClick={() => {
                  IsEditDefaultCategory ? updateCategory() : createCategory()
                }}
              >
                {IsEditDefaultCategory ? 'Update Category' : 'Add Category'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DefaultCategoryPlugin
