const PluginData = [
  {
    pluginId: 1,
    icon: '/media/plugins/jstree/profileQuestions.jpg',
    name: 'Ads Control',
    type: 'Software',
    description: 'change your ads key',
    status: 'Active',
    path: 'ads_plugin',
  },
  {
    pluginId: 2,
    icon: '/media/plugins/jstree/profileQuestions.jpg',
    name: 'Policy',
    type: 'Software',
    description: 'change policy Dynamically',
    status: 'Active',
    path: 'policy_plugin',
  },
  {
    pluginId: 3,
    icon: '/media/plugins/jstree/profileQuestions.jpg',
    name: 'SMTP Config',
    type: 'Software',
    description: 'change smtp key Dynamically',
    status: 'Active',
    path: 'smtp_plugin',
  },
  {
    pluginId: 4,
    icon: '/media/plugins/jstree/profileQuestions.jpg',
    name: 'Default Category',
    type: 'Software',
    description: 'update Default Category Setting',
    status: 'Active',
    path: 'category_plugin',
  },
  {
    pluginId: 5,
    icon: '/media/plugins/jstree/profileQuestions.jpg',
    name: 'Notification',
    type: 'Software',
    description: 'update your notification title and body',
    status: 'Active',
    path: 'notification_plugin',
  },
]

export default PluginData
