import React, {useState} from 'react'
import {KTCardBody} from '../../../../_metronic/helpers/components/KTCardBody'
import PluginData from '../plugins/PluginsData'
import clsx from 'clsx'
import {useIntl} from 'react-intl'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {Link, Route, Routes, useLocation} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import AdsControlPlugin from './Componants/AdsControl/AdsControlPlugin'
import LegalInformation from './Componants/LegalInformation/LegalInformationPlugin'
import SmtpPlugin from './Componants/SmtpConfig/smtpPlugin'
import DefaultCategoryPlugin from './Componants/DefaultCategory/defaultCategoryPlugin'
import NotificationPlugin from './Componants/Notification/NotificationPlugin'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Plugins',
    path: '/plugins',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const Plugins = () => {
  const intl = useIntl()
  const location = useLocation()
  const [pluginList, setPluginList] = useState(PluginData)

  return (
    <>
      <PageTitle breadcrumbs={usersBreadcrumbs}>
        {intl.formatMessage({id: 'MENU.PLUGINS'})}
      </PageTitle>
      {location.pathname === '/admin/plugins' && (
        <KTCardBody className='py-4 card'>
          <div className='table-responsive'>
            <table
              id='kt_table_users'
              className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
            >
              <thead>
                <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                  <td colSpan={4}>{intl.formatMessage({id: 'PLUGINS.TABLE.PLUGINNAME'})}</td>
                  <td colSpan={4}>{intl.formatMessage({id: 'PLUGINS.TABLE.DESCRIPTION'})}</td>
                  <td colSpan={2}>{intl.formatMessage({id: 'PLUGINS.TABLE.STATUS'})}</td>
                  <td colSpan={2}>{intl.formatMessage({id: 'PLUGINS.TABLE.ACTIONS'})}</td>
                </tr>
              </thead>
              <tbody className='text-gray-600 '>
                {pluginList.map((plugin) => {
                  return (
                    <tr>
                      <td colSpan={4}>
                        <div className='d-flex align-items-center '>
                          <div className='symbol symbol-50px overflow-visible me-3'>
                            <img
                              src={
                                toAbsoluteUrl(plugin.icon) ||
                                `https://preview.keenthemes.com/metronic8/react/demo1//media/avatars/300-6.jpg`
                              }
                              alt='icon'
                              width='50px'
                              height='50px'
                              loading='lazy'
                            />
                          </div>

                          <div className='flex-grow-1'>
                            <a href='#' className='text-gray-800 text-hover-primary fw-bold fs-4'>
                              {plugin.name}
                            </a>
                            <span className='text-muted fw-semibold d-block fs-6'>
                              {plugin.type}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td colSpan={4}>
                        <div className='text-muted fw-semibold fs-6'>{plugin.description}</div>
                      </td>
                      <td colSpan={2}>
                        <span
                          className={clsx('badge rounded-pill  ', {
                            'text-bg-success text-white': plugin.status === 'Active',
                            'text-bg-danger text-white': plugin.status === 'Disabled',
                          })}
                        >
                          {plugin.status}
                        </span>
                      </td>
                      <td colSpan={2}>
                        <Link to={`/admin/plugins/${plugin.path}`}>
                          <button className='btn btn-sm btn-light' data-kt-menu-dismiss='true'>
                            <i className='fa-solid fa-gear'></i>
                            Setting
                          </button>
                        </Link>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </KTCardBody>
      )}

      <Routes>
        <Route
          path='/ads_plugin'
          element={
            <>
              <PageTitle>Ads Plugin</PageTitle>
              <AdsControlPlugin />
            </>
          }
        />
        <Route
          path='/policy_plugin'
          element={
            <>
              <PageTitle>Policy Plugin</PageTitle>
              <LegalInformation />
            </>
          }
        />
        <Route
          path='/smtp_plugin'
          element={
            <>
              <PageTitle>SMTP Plugin</PageTitle>
              <SmtpPlugin />
            </>
          }
        />
        <Route
          path='/category_plugin'
          element={
            <>
              <PageTitle>Default Category Plugin</PageTitle>
              <DefaultCategoryPlugin />
            </>
          }
        />
        <Route
          path='/notification_plugin'
          element={
            <>
              <PageTitle>Notification Plugin</PageTitle>
              <NotificationPlugin />
            </>
          }
        />
      </Routes>
    </>
  )
}

export default Plugins
